import React, { useState, useRef, useEffect } from 'react'
import Boton from './Boton'


export default function Mensajeinput(params) {
    const [mensaje, setMensaje] = useState('');
    const [ok, setOk] = useState(true)
    const [selectedFile, setSelectedFile] = useState('');
    const [selectedFotoFile, setSelectedFotoFile] = useState('');
    const [link, setLink] = useState('');
    const [validationError, setValidationError] = useState(null);
    const fileInputRef = useRef(null);
    const fileFotoInputRef = useRef(null);
    const linkInputRef = useRef(null)
    const handleFotoFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedExtension = ['.jpg', '.png', '.jpeg', '.jfif'];
            const selectedFileExtension = file.name.split('.').pop().toLowerCase();
            if (allowedExtension.includes('.' + selectedFileExtension)) {
                setSelectedFotoFile(file);
            }
            else {
                setSelectedFotoFile(null);
                setValidationError('Por favor seleccione archivos con extensión: .jpg, .jpeg, .jfif, .png ');
                fileFotoInputRef.current.value = '';
            }
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedExtension = ['.pptx', '.docx', '.pdf', '.xlsx'];
            const selectedFileExtension = file.name.split('.').pop().toLowerCase();
            if (allowedExtension.includes('.' + selectedFileExtension)) {
                setSelectedFile(file);
                alert(file.name)
            }
            else {
                setSelectedFile(null);
                setValidationError('Por favor seleccione archivos con extensión:  .pdf .pptx .docx .xlsx ');
                fileInputRef.current.value = '';
            }
        }
    };
    const handleLinkChange = (event) => {
        const link = event.target.value;
        if (link) {
            console.log(link)
            setLink(link)
        }
    };
    function borraCaja(borrar) {
        if (borrar === true) { setMensaje(''); setSelectedFile(null); setSelectedFotoFile(null) }
    }
    useEffect(() => {
        setSelectedFotoFile(null)
        setSelectedFile(null)
        setMensaje('')
    }, [ok])
    return (
        <>
            <div id='inputfields' className=' flex flex-wrap gap-3 rounded-lg text-gray-700 bg-gray-200 
                border-violet-500 h-fit p-1'>
                <textarea value={mensaje}
                    id={'inputTextCaja'} type='text'
                    className='w-full h-full rounded-lg bg-gray-200 text-black placeholder:text-black '
                    placeholder={params.plantilla}
                    onChange={(e) => {
                        setMensaje(e.target.value);
                    }}>
                </textarea>

                {params.confoto && mensaje !== '' ?
                    <>
                        <div onClick={() => setValidationError("")} className='mx-auto flex-1 w-full bg-violet-800 text-white border-gray-100 border-2 rounded-lg text-center font-semibold py-1 pb-1 px-1'>
                            Agregar una Imagen
                            <div>
                                <input type='file' className=' flex-1 rounded-xl pt-1 pb-2 grid w-full text-xs text-wrap' ref={fileFotoInputRef}
                                    onChange={handleFotoFileChange} />
                            </div>
                        </div>
                    </>
                    :
                    <></>
                }
                {params.condoc && mensaje !== '' ?
                    <div onClick={() => setValidationError("")} className=' flex-1 w-full bg-blue-600  border-gray-100 border-2 rounded-lg text-center text-white font-semibold py-1 pb-1 px-1'>
                        Agregar un Documento
                        <div>
                            <input type='file' className='flex-1 text-white rounded-xl pt-1 pb-2 w-full text-xs text-wrap' ref={fileInputRef}
                                onChange={handleFileChange} />
                        </div>
                    </div>
                    : <></>
                }
                {params.conlink && mensaje !== '' ?
                    <div onClick={() => setValidationError("")}
                        className='mx-auto w-full bg-violet-800 text-white border-gray-100 border-2 rounded-lg text-center font-semibold py-1 pb-1 px-1'>
                        Agregar un Link
                        <div>
                            <input type='text'
                                className='text-black flex-1 rounded-xl pt-1 pb-2 grid w-full text-xs text-wrap'
                                ref={linkInputRef}
                                onChange={handleLinkChange} />
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>
            <div className=' pt-2 pb-1 flex gap-2'>{validationError}
                <Boton textBoton={'Enviar'} funcion={() => {
                    params.funcion({ msg: mensaje, file: selectedFotoFile, doc: selectedFile, link: link });
                    borraCaja(params.borrar);
                }} />
                {params.cancelar ? <Boton textBoton="Cancelar" funcion={() => { setOk(!ok) }} /> : ''}
            </div>
        </>
    )
}



